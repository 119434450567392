// extracted by mini-css-extract-plugin
export var aireGCCReplicaBG = "tools-module--aireGCCReplicaBG--GkQDR";
export var aireIndiaHeroBG = "tools-module--aireIndiaHeroBG--H4Qw9";
export var aireUSHeroBG = "tools-module--aireUSHeroBG--8YhB1";
export var backgroundCoverImg = "tools-module--backgroundCoverImg--GHHIo";
export var backgroundCoverImgHrms = "tools-module--backgroundCoverImgHrms--mX7-J";
export var chevron = "tools-module--chevron--5sybD";
export var chevronContainer = "tools-module--chevronContainer--lBlhg";
export var chevronContainerHrms = "tools-module--chevronContainerHrms--B2TyK";
export var emotionalImg = "tools-module--emotionalImg--4a-TQ";
export var headingShrm = "tools-module--headingShrm--p5enZ";